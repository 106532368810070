import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { Input, Button } from "react-daisyui"
import { useBannerStore, BannerStore, BannerStoreContext } from "./store/BannerStore";
import { observer } from "mobx-react";

const AddBannerManagement = observer(() => {
    const store = new BannerStore({
        isloadingtype:true,
        isloading:false,
        editloading:false
      });
    return (
        <BannerStoreContext.Provider value={store}>
            <Screen />
        </BannerStoreContext.Provider>
    )
})

export default AddBannerManagement;

const Screen = observer(() => {
    const { 
        saved,
        loadBanner,
        image,
        Screen,
        imageurl,
        setname,
        setstatus,
        setkeywords,
        setimage,
        addbanner,
        setScreen,
        setlink,
        setcheckbox,
        checkbox,
        link,
        Producttypelist,
        addedloadbanner
      } = useBannerStore();

      const navigate = useNavigate();
      useEffect(() => {
        if (saved) {
          navigate("../bannermanagement");
        }
      }, [saved,loadBanner]);
      const handleCheckboxChange = (e) => {
        const value = e.target.checked ? 1 : 0;
        setcheckbox(value);
        setlink("")
      };
      const [error, setError] = useState('');

      const handleImageChange = (e) => {
        const file = e.target.files[0];
    
        // Check if a file is selected
        if (file) {
            // Create an image element to check the dimensions
            const img = new Image();
            img.onload = () => {
                const { width, height } = img;
                const requiredWidth = 1312;  // Set your required width
                const requiredHeight = 471; // Set your required height
    
                // Validate dimensions
                if (width !== requiredWidth || height !== requiredHeight) {
                    setError(`Image must be ${requiredWidth}x${requiredHeight} pixels.`);
    
                    // Reset the input field and clear the image URL if dimensions don't match
                    e.target.value = '';  // Reset the input field
                    // setimageUrl('');      // Clear the image URL
                    setimage(null);       // Reset the image state
                } else {
                    setimage(file);  // Set the image if dimensions are valid
                    setError(''); // Clear the error
                    // setimageUrl(URL.createObjectURL(file));  // Create and set the image URL
                }
            };
            img.src = URL.createObjectURL(file);
        }
    };
    
    return (
        <>
        <div className='flex justify-between'>
            <div className=" mb-4">
                <p className=" text-2xl font-semibold text-moredarkgreen">Add Banner</p>
                {/* <p className=" opacity-80 text-sm">Home /City/ Edit</p> */}
            </div>
            <div>
                <Link to="/bannermanagement" className="btn btn-sm bg-darkgreen text-yellow text-sm hover:bg-yellow hover:text-darkgreen ">Back</Link>
            </div>
        </div>  
            <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
                <form onSubmit={(e) => {addbanner();e.preventDefault() }}>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                        <div>
                            <label htmlFor="image" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">File<span className="text-[#f00]">&nbsp;*</span></label>
                            <div className=" grid grid-cols-3">
                            <Input
                                name="image"
                                required
                                placeholder="Image"
                               accept="image/*"
                                onChange={handleImageChange}
                                type="file"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md col-span-2"
                            />
                            
                            {imageurl && <img className="w-full" src={imageurl}/> }
                            </div>
                            {error ? <p className="text-red-500 text-sm text-error">{error}</p> : (!imageurl ? <p className="text-red-500 text-sm ">Image must be 1312x471 pixels.</p> : "")}
                        </div>
                        <div>
                            <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status<span className="text-[#f00]">&nbsp;*</span></label>
                            <select
                                id="Status"
                                required
                                name="Status"
                                onChange={(e)=> {setstatus(e.target.value)}}
                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                                <option value="" disabled selected className="hidden">Select Status</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive </option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="is_internal_link" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Is Internal Link</label>
                            <div className=" grid grid-cols-3">
                            <Input
                                name="is_internal_link"
                                id="is_internal_link"
                                // defaultValue=""
                                onChange={handleCheckboxChange}
                                type="checkbox"
                                autoComplete="off"
                                className="focus:outline-0 w-5 h-5 text-sm font-medium rounded-md "
                            />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="link" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Link<span className="text-[#f00]">&nbsp;*</span></label>
                        {checkbox ? 
                            <select
                            id="link"
                            required
                            name="link"
                            onChange={(e)=> {setlink(e.target.value);}}
                            className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                            <option value="" disabled selected className="hidden">Select Link</option>
                            <option value="Theme">Theme</option>
                            <option value="Explore">Explore</option>
                            <option value="Premium">Premium</option>
                            </select>
                            :
                            <Input
                                required
                                name="link"
                                placeholder="Link"
                                id="link"
                                pattern="https://.*"
                                type="url"
                                value={link}
                                onChange={(e)=> {setlink(e.target.value)}}
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                            }
                        </div>
                       
                    </div>
                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <Link to="/bannermanagement" className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen "><MdOutlineClose className="mr-1"/>Cancel</Link>

                        {   
                        !addedloadbanner ?
                        <button type="submit"  className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4" ><MdOutlineCheck className="mr-1"/>Add Banner</button>
                        :
                        <button 
                        className="btn btn-md opacity-80 bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4"
                          type="button"
                        //  onClick={()=> changeStep("4")}
                        >
                        <svg
                            className="animate-spin mr-1 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            >
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                          Loading..
                        </button>
                        }
                    </div>
                </form>
            </div>

        </>
    )
})
