import {createContext,useContext} from 'react'
import { makeAutoObservable, runInAction } from "mobx";
import {getMethodData,putMethod, patchMethodData, postFormData} from "../../ApiMethods/api"
import { toast } from "react-toastify";

export class BannerStore {
    Token = null;
    indexPage=1;
    activePage=1;
    checkbox = 0;
    Count=1;
    perPage="";
    filterVisibility=true;
    banners = [];
    loadBanner = false;
    errloadBanner = "";
    nolist = false;
    filtertrue=false;
    singleBanner;
    nofilterwithpagination= false;
    addloadbanner= false;
    addedloadbanner= false;
    erraddbanner="";
    name= "";
    keywords="";
    status="";
    image="";
    imageurl="";
    Screen="";
    price= "";
    bannerid= "";
    link="";
    saved = false

    loadProducttype=false;
    errProducttypelist = "";
    noProducttypelist=true;
    Producttypelist=[]
    previousValues = {
      name: "",
      status:"",
      type_id:""
    };

    constructor(isloading = false) {
        makeAutoObservable(this);
       
        this.Token = localStorage.getItem("Admintoken");
        if(isloading.isloadingtype){

        }
        if(isloading.isloading){
            this.getBanners(isloading.pagginationcount);
            return
        }
        if(isloading.editloading){
            this.getBannerone(isloading.id);

            return
        }
    }

    setname = (text) =>  {
        this.name = text;
    }
    setToken = (text) =>  {
      this.Token = text;
    }
    setScreen = (text) =>  {
      this.Screen = text;
  }
    setkeywords = (text) =>  {
        this.keywords = text;
    }
    setstatus = (text) =>  {
        this.status = text;
    }
    setimage = (text) =>  {
      if(text){
        this.image = text;
        this.imageurl = URL.createObjectURL(text);
      }else{
        this.image = "";
        this.imageurl ="";
      }
    }

    setlink = (text) =>  {
      this.link = text;
  }

    getstatusid = (text) => {
        this.bannerid = text;
    }
    setActivePage = (text) =>{
      this.activePage = text;
    }
    setIndexPage=(e)=>{
      this.indexPage=e;
    }
    setCount=(e)=>{
      this.Count=e;
    }
    setPerPage=(e)=>{
      this.perPage=e;
    }
    setFilterVisibility=(e)=>{
      this.filterVisibility=e;
    }
    setcheckbox=(text)=>{
      this.checkbox =text
  
    }
    NofilterwithPagination = (e) => {
      runInAction(() => {
          this.nofilterwithpagination = e
      })
    }

           // get product type list
           getProducttypeList = (Token) => {
            runInAction(() => {
              this.loadProducttype = true;
              this.errProducttypelist = "";
            });
            getMethodData(`/admin/product-types`, this.Token||Token)
              .then((resp) => {
                if (resp?.data?.status === false) {
                  runInAction(() => {
                    this.loadProducttype = false;
                    this.errProducttypelist = "";
                  });
                  return;
                }
                if (resp?.data?.status === true) {
                  runInAction(() => {
                    this.loadProducttype = false;
                    this.errProducttypelist = resp?.data?.message;
                    if (resp?.data?.data?.length === 0) {
                      this.noProducttypelist = true;
                    } else {
                      this.noProducttypelist = false;
                    }
                    this.Producttypelist = resp?.data?.data;
                  });
                  return;
                }
                runInAction(() => {
                  this.loadProducttype = false;
                  this.errProducttypelist = resp?.data?.message;
                });
              })
              .catch((error) => {
                runInAction(() => {
                  this.loadProducttype = false;
                  if (!error.response) {
                    toast.error(error?.message);
                  }
                  toast.error(error?.response?.data?.message);
                });
                return;
              });
          };

        // get banner List
        getBanners = (pageNumber,Token) => {
            runInAction(() => {
                this.loadBanner = true;
                this.errloadBanner = "";
                this.saved = false;
                this.banners=[];
                this.indexPage = pageNumber;
                });
            // getMethodData("/admin/banners",this.Token)
            getMethodData(`/admin/banners?page=${pageNumber}`,this.Token||Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                    this.loadBanner = false;
                    this.errloadBanner = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                    this.loadBanner = false;
                    this.errloadBanner = resp?.data?.message;
                    // console.log(resp?.data?.data.length);
                    if(resp?.data?.data?.data?.length === 0) {
                        this.nolist = true;
                    }else{
                        this.nolist = false;
                    }
                    // console.log(resp?.data?.data?.data)
                    this.banners = resp?.data?.data?.data;
                    this.Count = resp?.data?.data?.total;
                    this.perPage = resp?.data?.data?.per_page;
                    this.activePage = resp?.data?.data?.current_page;
                    this.saved = false;
                    this.filterVisibility = true;
                    // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadBanner = false;
                    this.errloadBanner = resp?.data?.message;
                });
                })
                .catch((error) => {
                runInAction(() => {
                    this.loadBanner = false;
                    if(!error.response){
                        this.errloadBanner = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadBanner = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
        };
        loadTableData = async (pageNumber) => {
          runInAction(() => {
            this.loadBanner = true;
            this.errloadBanner = "";
            this.saved = false;
            this.setIndexPage(1);
            });
          try {
            const response = await getMethodData(`/admin/banners?page=${pageNumber === 1 ? 1 : pageNumber}`,this.Token)
            if (response?.data?.status === false) {
              runInAction(() => {
              this.loadBanner = false;
              this.errloadBanner = response?.data?.message
              });
              return;
          }
          if (response?.data?.status === true) {
            runInAction(() => {
              this.loadBanner = false;
              this.errloadBanner = response?.data?.message;
              // console.log(resp?.data?.data.length);
              if(response?.data?.data?.data?.length === 0) {
                  this.nolist = true;
              }else{
                  this.nolist = false;
              }
                this.setCount(response.data.data.total);
                this.setPerPage(response.data.data.per_page);
                this.setActivePage(response.data.data.current_page)
                this.banners = response?.data?.data?.data;
                this.filterVisibility = true;
    });
          }
    
        
      
          } catch (error) {
            runInAction(() => {
              this.loadBanner = false;
              console.error("Error loading table data:", error);
          });
          }
        };
            //get banner edit data (show)

    getBannerone =(id) => {
        getMethodData( `/admin/banners/edit/${id}`, this.Token)
        .then((resp) => {
            runInAction(() => {
                this.loadBanner = true;
                this.errloadBanner = "";
              });
          if (resp?.data?.status === false) {
            runInAction(() => {
              this.loadBanner = false;
              this.errloadBanner = resp.data.message;
              // toast.error(resp.data.message);
            });
            return;
          }
          if (resp?.data?.status === true) {
            runInAction(() => {
                this.loadBanner = false;
                this.singleBanner =  resp?.data?.data
                // this.Screen = resp?.data?.data?.screen
                this.link = resp?.data?.data?.link
                this.checkbox= resp?.data?.data?.is_internal_link
                this.status = resp?.data?.data?.status
                this.image = resp?.data?.data?.image
                this.imageurl = resp?.data?.data?.image
            })
            return;
          }
          runInAction(() => {
            this.loadBanner = false;
            this.errloadBanner = resp.data.message;
            toast.error(resp.data.message);
          });
        })
        .catch((error) => {
          runInAction(() => {
              if(!error.response){
                this.loadBanner = false;
                this.errloadBanner = error?.message;
                toast.error(error?.message);
              }
                toast.error(error?.response?.data?.message);
                this.loadBanner = false;
                this.errloadBanner = error?.response?.data?.message;
          })
          return;
      })
    }


         // banner add

 addbanner = () => {
    runInAction(() => {
      this.addedloadbanner = true;
      this.erraddbanner = "";
    });
    const formData = new FormData();
    formData.append("link", this.link);
    formData.append("is_internal_link", this.checkbox);
    formData.append("status", this.status);
    formData.append("image", this.image);
    postFormData(`/admin/banners/store`,formData,this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.addedloadbanner = false;
            this.erraddbanner = resp?.data?.message;
            toast.error(resp.data?.data?.errors?.name[0]);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
              this.addedloadbanner = false;
              this.erraddbanner = resp?.data?.message;
              toast.success(resp.data.message);
              //  this.getBanners();
              this.saved = true;
            })
            return
        }
        runInAction(() => {
          this.addedloadbanner = false;
          this.erraddbanner = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        
        runInAction(() => {
            if(!error.response){
                
              this.addedloadbanner = false;
              this.erraddbanner = error?.message;
              toast.error(error?.message);
            }
                
              toast.error(error?.response?.data?.message);
              this.addedloadbanner = false;
              this.erraddbanner = error?.response?.data?.message;
        })
        return;
    })
  };

  editbanner = (id) => {
    runInAction(() => {
      this.addloadbanner = true;
      this.erraddbanner = "";
    });
    const formData = new FormData();
    formData.append("is_internal_link", this.checkbox);
    formData.append("link", this.link);
    formData.append("status", this.status);
    if (this.image) {
        formData.append("image", this.image);
      } else {
        formData.delete("image");
    }
    postFormData(`/admin/banners/update/${id}`,formData,this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.addloadbanner = false;
            this.erraddbanner = resp?.data?.message;
            toast.error(resp.data?.data?.errors?.name[0]);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
              this.addloadbanner = false;
              this.erraddbanner = resp?.data?.message;
              toast.success(resp.data.message);
              //  this.getBanners();
              this.saved = true;
            })
            return
        }
        runInAction(() => {
          this.addloadbanner = false;
          this.erraddbanner = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        
        runInAction(() => {
            if(!error.response){
              this.addloadbanner = false;
              this.erraddbanner = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              this.addloadbanner = false;
              this.erraddbanner = error?.response?.data?.message;
        })
        return;
    })
  };


    //Active and inactive
statusChange = (currentPage) => {
    runInAction(() => {
      this.loadBanner = true;
      this.errloadBanner = "";
    });
    getMethodData(`/admin/banners/change-status/${this.bannerid}`,this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadBanner = false;
            this.errloadBanner = resp?.data?.message;
            // toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadBanner = false;
            this.errloadBanner = resp?.data?.message;
            this.getBanners(currentPage ?? 1);
            toast.success(resp?.data?.message);
          })
          this.setIndexPage(currentPage);
          return;
        }
        runInAction(() => {
          this.loadBanner = false;
          this.errloadBanner = resp?.data?.message;
        })
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
              this.loadBanner = false;
              this.errloadBanner = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              this.loadBanner = false;
              this.errloadBanner = error?.response?.data?.message;
        })
        return;
    })
  };
setfiltertrue=(e)=>{
  this.filtertrue = e;
}

     //filter user api 

     getFilterBanner = (page,Token) => {
        runInAction(() => {
            this.activePage = page;
            this.loadBanner = true;
            this.indexPage = page;
            this.errloadBanner = "";
            this.saved = false;
            this.banners=[];
            if(!this.nofilterwithpagination){
              this.previousValues = {
                status: this.status,
                screen: this.Screen,
              };
            }
          });
      
          if(this.nofilterwithpagination){
            getMethodData(`/admin/banners?page=${page}&status=${ this.previousValues?.status}&screen=${ this.previousValues?.screen}`,this.Token||Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                  runInAction(() => {
                    this.loadBanner = false;
                    this.errloadBanner = resp?.data?.message
                  });
                  return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.loadBanner = false;
                        this.filtertrue = true;
                        this.errloadBanner = resp?.data?.message;
                        // console.log(resp?.data?.data.length);
                        if(resp?.data?.data?.data?.length === 0) {
                            this.nolist = true;
                        }else{
                            this.nolist = false;
                        }
                        // console.log(resp?.data?.data?.data)
                        this.banners = resp?.data?.data?.data;
                        this.Count = resp.data.data.total;
                        this.perPage = resp.data.data.per_page;
                        this.activePage = resp?.data?.data?.current_page;
                        this.saved = false;
                        this.filterVisibility = true;
                        // this.status = true;
                        });
                  return;
                }
                runInAction(() => {
                  this.loadBanner = false;
                  this.errloadBanner = resp?.data?.message;
                });
              })
              return;
            
          }
        getMethodData(`/admin/banners?page=${page}&status=${this.status}&screen=${this.Screen}`,this.Token||Token)
        .then((resp) => {
            if (resp?.data?.status === false) {
              runInAction(() => {
                this.loadBanner = false;
                this.errloadBanner = resp?.data?.message
              });
              return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.loadBanner = false;
                    this.filtertrue = true;
                    this.errloadBanner = resp?.data?.message;
                    // console.log(resp?.data?.data.length);
                    if(resp?.data?.data?.data?.length === 0) {
                        this.nolist = true;
                    }else{
                        this.nolist = false;
                    }
                    // console.log(resp?.data?.data?.data)
                    this.banners = resp?.data?.data?.data;
                    this.Count = resp.data.data.total;
                    this.perPage = resp.data.data.per_page;
                    this.activePage = resp?.data?.data?.current_page;
                    this.saved = false;
                    this.filterVisibility = true;
                    // this.status = true;
                    });
              return;
            }
            runInAction(() => {
              this.loadBanner = false;
              this.errloadBanner = resp?.data?.message;
            });
          })
          .catch((error) => {
            runInAction(() => {
                this.loadBanner = false;
                if(!error.response){
                    this.errloadBanner = error?.message;
                    toast.error(error?.message);
                }
                this.errloadBanner = error?.response?.data?.message;
                toast.error(error?.response?.data?.message);
            })
            return;
        });
    };


};


export const BannerStoreContext = createContext(new BannerStore());
export const useBannerStore = () => useContext(BannerStoreContext);